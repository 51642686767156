export const getAccountNotificationsItems = async (adminUserId, token) => {
  const response = await fetch(
    `/api/items/accountNotification?adminUserId=${adminUserId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    }
  );
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.message);
  }
  return body;
};
